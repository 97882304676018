<template>
  <div>
    <!-- Team Introduction Section -->
    <div class="teamIntro d-flex justify-content-center align-items-center text-center py-5">
      <div class="teamIntroContent">
        <h1 class="display-4">Meet Our Team</h1>
        <p class="lead">Dedicated professionals driving innovation and excellence.</p>
        <p>Our team of experts is passionate about delivering cutting-edge solutions and services tailored to your needs. Get to know the people behind the magic.</p>
      </div>
    </div>

    <!-- Team Members Section -->
    <div class="teamMembers d-flex flex-wrap justify-content-center py-5">
      <div class="teamMemberCard team-slide-in d-flex flex-column align-items-center text-center mx-3 mb-4">
        <img src="@/assets/Team/Profile/ProfileG.png" alt="Team Member 1" class="teamMemberPhoto mb-3" />
        <h3 class="teamMemberName">S M Kumara</h3>
        <p class="teamMemberRole">Lead Developer</p>
        <p class="teamMemberDescription">Kumara is an experienced developer specializing in full-stack solutions with a passion for innovative technologies.</p>
      </div>
      <div class="teamMemberCard team-slide-in d-flex flex-column align-items-center text-center mx-3 mb-4">
        <img src="@/assets/Team/Profile/ProfileG.png" alt="Team Member 3" class="teamMemberPhoto mb-3" />
        <h3 class="teamMemberName">K S Sahan</h3>
        <p class="teamMemberRole">Project Manager</p>
        <p class="teamMemberDescription">Sahan ensures that projects are delivered on time and within budget, coordinating seamlessly with clients and team members.</p>
      </div>
      <div class="teamMemberCard team-slide-in d-flex flex-column align-items-center text-center mx-3 mb-4">
        <img src="@/assets/Team/Profile/ProfileG.png" alt="Team Member 4" class="teamMemberPhoto mb-3" />
        <h3 class="teamMemberName">D P Waruna</h3>
        <p class="teamMemberRole">Backend Developer</p>
        <p class="teamMemberDescription">Waruna focuses on building robust backend systems, optimizing performance, and ensuring security.</p>
      </div>
      <div class="teamMemberCard team-slide-in d-flex flex-column align-items-center text-center mx-3 mb-4">
        <img src="@/assets/Team/Profile/ProfileF.png" alt="Team Member 6" class="teamMemberPhoto mb-3" />
        <h3 class="teamMemberName">Hiruni Imasha</h3>
        <p class="teamMemberRole">Graphic Designer</p>
        <p class="teamMemberDescription">Imasha excels in transforming design concepts into seamless, interactive user interfaces with a focus on performance.</p>
      </div>
      <div class="teamMemberCard team-slide-in d-flex flex-column align-items-center text-center mx-3 mb-4">
        <img src="@/assets/Team/Profile/ProfileF.png" alt="Team Member 2" class="teamMemberPhoto mb-3" />
        <h3 class="teamMemberName">Imalka Lakmali</h3>
        <p class="teamMemberRole">UI/UX Designer</p>
        <p class="teamMemberDescription">Imalka crafts intuitive user experiences and visually stunning interfaces to enhance user engagement.</p>
      </div>
      <div class="teamMemberCard team-slide-in d-flex flex-column align-items-center text-center mx-3 mb-4">
        <img src="@/assets/Team/Profile/ProfileG.png" alt="Team Member 5" class="teamMemberPhoto mb-3" />
        <h3 class="teamMemberName">Dinesh DPK</h3>
        <p class="teamMemberRole">Front-End Developer</p>
        <p class="teamMemberDescription">Dinesh specializes in creating responsive, user-friendly interfaces that deliver exceptional user experiences.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamPage',
}
</script>

<style lang="scss" scoped>
.teamIntro {
  width: 100%;
  height: 80vh; /* Adjusted for viewport height */
  background-color: #007bff; /* Blue background color */
  color: #fff; /* White text color for contrast */
  padding: 2rem; /* Added padding for spacing */
  background-image: url('@/assets/Team/TeamImg.jpg'); /* Replace with your image path */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; /* Center background image */
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5); /* Text shadow for readability */
}

.teamIntroContent {
  max-width: 800px;
}

.teamIntro h1 {
  color: #ffffff; /* White color for heading */
}

.teamIntro p {
  font-size: 1.125rem;
}

.teamMembers {
  background-color: #f8f9fa; /* Light background color */
}

.teamMemberCard {
  background-color: #ffffff; /* Card background color */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Box-shadow for depth */
  width: 300px; /* Fixed width for cards */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.teamMemberCard:hover {
  transform: translateY(-10px); /* Lift effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4); /* Enhanced box-shadow on hover */
}

.teamMemberPhoto {
  border-radius: 50%; /* Circular image */
  width: 150px; /* Fixed width for photos */
  height: 150px; /* Fixed height for photos */
  object-fit: cover; /* Maintain aspect ratio */
}

.teamMemberName {
  margin-top: 1rem;
  font-size: 1.5rem; /* Larger font size for names */
}

.teamMemberRole {
  font-size: 1.125rem; /* Font size for roles */
  color: #007bff; /* Blue color for roles */
}

.teamMemberDescription {
  color: #6c757d; /* Gray color for descriptions */
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.team-slide-in {
  animation: slideIn 0.5s ease-out;
}

@media (max-width: 767px) {
  .teamMemberCard {
    width: 100%; /* Full width on small screens */
  }
}
</style>
