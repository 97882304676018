<template>
  <div>
    <!-- Contact Us Intro Section -->
    <div class="contactIntro d-flex justify-content-center align-items-center text-center py-5">
      <div class="contactIntroContent">
        <h1 class="display-4">Get in Touch</h1>
        <p class="lead">We're here to help you with any questions or concerns you may have.</p>
        <p>Our team at My Soft Code is dedicated to providing top-notch support and solutions tailored to your needs. Reach out to us, and we'll be happy to assist you.</p>
      </div>
    </div>

    <!-- Contact Form Section -->
    <div class="contactFormSection d-flex flex-column align-items-center py-5">
      <div class="contactForm w-100 w-md-75 w-lg-50">
        <form @submit.prevent="handleSubmit">
          <div class="row mb-4">
            <div class="col-md-6">
              <div class="form-group">
                <label for="name" class="form-label">Name</label>
                <input type="text" id="name" class="form-control" v-model="form.name" required />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="email" class="form-label">Email</label>
                <input type="email" id="email" class="form-control" v-model="form.email" required />
              </div>
            </div>
          </div>
          <div class="form-group mb-4">
            <label for="message" class="form-label">Message</label>
            <textarea id="message" class="form-control" rows="5" v-model="form.message" required></textarea>
          </div>
          <button type="submit" class="btn btn-primary">Send Message</button>
        </form>
      </div>
    </div>

    <!-- Contact Info Section -->
    <div class="contactInfoSection d-flex flex-column align-items-center py-5">
      <h2 class="display-5 mb-3">Our Contact Information</h2>
      <div class="info-item d-flex align-items-center mb-3">
        <i class="bi bi-telephone fa-2x me-3"></i>
        <span>+94 00 0000 000</span>
      </div>
      <div class="info-item d-flex align-items-center mb-3">
        <i class="bi bi-envelope fa-2x me-3"></i>
        <span>info@mysoftcode.com</span>
      </div>
      <div class="info-item d-flex align-items-center mb-3">
        <i class="bi bi-geo-alt fa-2x me-3"></i>
        <span>Dambulla, Sri Lanka</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      }
    }
  },
  methods: {
    handleSubmit() {
      // Handle form submission here
      alert('Message sent!'); // Example alert, replace with your logic
    }
  }
}
</script>

<style lang="scss" scoped>
.contactIntro {
  width: 100%;
  height: 80vh; /* Adjusted for viewport height */
  background-color: #6c757d; /* Darker background color */
  color: #fff; /* White text color for contrast */
  padding: 2rem; /* Added padding for spacing */
  background-image: url('@/assets/Contact/ContactImg.jpg'); /* Replace with your image path */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; /* Center background image */
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5); /* Text shadow for readability */
}

.contactIntroContent {
  max-width: 800px;
}

.contactIntro h1 {
  color: #ffd900; /* Primary color for heading */
}

.contactIntro p {
  font-size: 1.125rem;
}

.contactFormSection {
  background-color: #a8a8a8; /* White background for the form */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box-shadow for depth */
  padding: 2rem;
  margin-bottom: 2rem; /* Spacing between form and contact info */
}

.contactForm .form-control {
  border-radius: 5px; /* Rounded corners for inputs */
}

.contactForm .btn-primary {
  background-color: #007bff; /* Primary button color */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  padding: 0.75rem 1.5rem; /* Padding */
}

.contactForm .btn-primary:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.contactInfoSection {
  text-align: center;
}

.info-item {
  color: #343a40; /* Dark text color */
}

.info-item i {
  color: #007bff; /* Icon color */
}

.info-item span {
  font-size: 1.125rem; /* Font size for contact details */
}

@media (max-width: 767px) {
  .contactFormSection {
    padding: 1.5rem;
  }
}
</style>
