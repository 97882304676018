import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ContactView from '../views/ContactView.vue'
import TeamView from '../views/TeamView.vue'
import GraphicDesign from '@/views/services/GraphicDesign.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/team',
    name: 'team',
    component: TeamView
  },

  {
    path: '/graphicDesign',
    name: 'graphicDesign',
    component: GraphicDesign
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
