<template>
<div class="graphicDesignClass">
  <div>
    <HeaderView />
  </div>
  <div>
    <GraphicDesignA />
  </div>
  <div>
    <FooterView />
  </div>
</div>
</template>

<script>
import HeaderView from "@/components/HeaderView.vue";
import FooterView from "@/components/FooterView.vue";
import GraphicDesignA from "@/components/ServicePages/GraphicDesignPage/GraphicDesignA.vue";

export default {
  name: "ColorRain",
  components: {
    HeaderView,
    GraphicDesignA,
    FooterView,
  },
};
</script>

<style scoped>

</style>
  