<template>
  <div id="myapp" class="headerClass">
    <!-- Sidebar and Main Content -->
    <div class="appClass">
      <!-- Sidebar -->
      <div :class="['sidebar', { 'sidebar-open': isSidebarOpen }]">
          <div style="position: absolute; top: 0; right: 0; width: auto;">
              <button class="btn btn-secondary close-btn mobile-only" @click="toggleSidebar">
                  <i class="bi" :class="isSidebarOpen ? 'bi-x' : 'bi-list'"></i>
              </button>
              <button class="btn btn-warning lock-btn desktop-only" @click="toggleLock">
          <i class="bi" :class="isSidebarLocked ? 'bi-lock' : 'bi-unlock'"></i>
        </button>
          </div>

        <div style="text-align: center;">
          <div></div>
          <img src="@/assets/MySoftCode.png" alt="Logo" width="30" height="24" class="d-inline-block align-text-top">
          <h3 style="color: white; padding: 10px;">My Soft Code</h3>
          <hr style="border: 1px solid white; width: 80%; margin: 0 auto;" />
        </div>



        <!-- <button class="btn btn-secondary close-btn" @click="toggleSidebar">
          <i class="bi" :class="isSidebarOpen ? 'bi-x' : 'bi-list'"></i>
        </button> -->
        <div class="sidebar-content">

          <div class="sideBar">
            <ul style="position: absolute; left: 10%;" >
  <li>
    <router-link to="/">
      <i class="bi bi-house-door"></i> Home
    </router-link>
  </li>
  <li>
    <router-link to="/about">
      <i class="bi bi-info-circle"></i> About
    </router-link>
  </li>
  <li @click="toggleServices">
    <i class="bi bi-briefcase"></i> Services
  </li>
  <ul v-if="showServices">
    <li>
      <router-link to="/graphicDesign">
        <i class="bi bi-palette"></i> Graphic Design
      </router-link>
    </li>
    <li>
      <router-link to="#">
        <i class="bi bi-windows"></i> Windows Application
      </router-link>
    </li>
    <li @click="toggleMobileApps">
      <i class="bi bi-phone"></i> Mobile Application
    </li>
    <ul v-if="showMobileApps">
      <li>
        <router-link to="#">
          <i class="bi bi-android2"></i> Android
        </router-link>
      </li>
      <li>
        <router-link to="#">
          <i class="bi bi-apple"></i> Apple
        </router-link>
      </li>
    </ul>
    <li>
      <router-link to="/#">
        <i class="bi bi-globe"></i> Web Application
      </router-link>
    </li>
  </ul>
  <li>
    <router-link to="/team">
      <i class="bi bi-people"></i> Team
    </router-link>
  </li>
  <li>
    <router-link to="/contact">
      <i class="bi bi-envelope"></i> Contact
    </router-link>
  </li>
</ul>

  </div>


        </div>
      </div>

      <!-- Main Content -->
      <main :class="['main-content', { 'shift-right': isSidebarOpen }]">
        <nav class="navbar navbar-expand-lg navbar-dark custom-navbar">
          <div style="display: flex;">
          <a v-show="!isSidebarOpen" class="navbar-brand appName" href="#">My Soft Code</a>
          <button class="btn btn-primary ms-auto mobile-only toglebtn" @click="toggleSidebar">
            <i class="bi" :class="isSidebarOpen ? 'bi-x' : 'bi-list'"></i> <!-- Toggle icon -->
          </button>
          
          </div>
          <a class="navbar-brand appNameA" href="#">My Soft Code</a>
          <div class="collapse navbar-collapse" id="navbarNav">

            <!-- <h3>My Soft Code</h3> -->
            <router-link to="/" class="nav-link active"><a class="navbar-brand" href="#">
          <img src="@/assets/MySoftCode.png" alt="Logo" width="30" height="24" class="d-inline-block align-text-top">
          My Soft Code
        </a></router-link>




            <div class="appNameB">

              <ul class="navbar-nav">
  <li class="nav-item">
    <router-link to="/" class="nav-link active" aria-current="page">
      <i class="bi bi-house-door"></i> Home
    </router-link>
  </li>
  <li class="nav-item">
    <router-link to="/about" class="nav-link" aria-current="page">
      <i class="bi bi-info-circle"></i> About
    </router-link>
  </li>
  <li class="nav-item dropdown">
    <router-link to="#" class="nav-link dropdown-toggle" id="featuresDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="bi bi-briefcase"></i> Services
    </router-link>
    <ul class="dropdown-menu" aria-labelledby="featuresDropdown">
      <li>
        <router-link to="/graphicDesign" class="dropdown-item">
          <i class="bi bi-palette"></i> Graphic Design
        </router-link>
      </li>
      <li>
        <router-link to="#" class="dropdown-item">
          <i class="bi bi-windows"></i> Windows Application
        </router-link>
      </li>
      <li class="dropdown-submenu">
        <router-link to="#" class="dropdown-item dropdown-toggle">
          <i class="bi bi-phone"></i> Mobile Application
        </router-link>
        <ul class="dropdown-menu">
          <li>
            <router-link to="#" class="dropdown-item">
              <i class="bi bi-android2"></i> Android
            </router-link>
          </li>
          <li>
            <router-link to="#" class="dropdown-item">
              <i class="bi bi-apple"></i> Apple
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link to="#" class="dropdown-item">
          <i class="bi bi-globe"></i> Web Application
        </router-link>
      </li>
    </ul>
  </li>
  <li class="nav-item">
    <router-link to="/team" class="nav-link active" aria-current="page">
      <i class="bi bi-people"></i> Team
    </router-link>
  </li>
  <li class="nav-item">
    <router-link to="/contact" class="nav-link active" aria-current="page">
      <i class="bi bi-envelope"></i> Contact
    </router-link>
  </li>
</ul>

          </div>


          </div>


          <div class="loginHeader desktop-only">
              <LoginHeader/>
          </div>

          <div v-show="!isSidebarOpen" class="loginHeader mobile-only">
              <LoginHeader/>
          </div>
        </nav>

        <div>
          <div class="main-content">
          </div>
        </div>

        <!-- Add your main content here -->
      </main>
    </div>
  </div>
</template>

<script>
import LoginHeader from '@/components/LoginHeader.vue';

export default {
  components: {
      LoginHeader,
  },
  data() {
    return {
      showServices: false,
      showMobileApps: false,

      isSidebarOpen: false,
      isSidebarLocked: false,
      currentPage: 'home',
    };
  },
  methods: {

    toggleServices() {
      this.showServices = !this.showServices;
      // Optionally close Mobile Apps submenu when closing Services
      if (!this.showServices) {
        this.showMobileApps = false;
      }
    },
    toggleMobileApps() {
      this.showMobileApps = !this.showMobileApps;
    },
    
    // toggleSidebar() {
    //   this.isSidebarOpen = !this.isSidebarOpen;
    // },
    toggleSidebar() {
    if (!this.isSidebarLocked) {
      this.isSidebarOpen = !this.isSidebarOpen;
    }
  },
    // showPage(page) {
    //   this.currentPage = page;
    //   this.isSidebarOpen = false;
    // },

    toggleLock() {
    this.isSidebarLocked = !this.isSidebarLocked;
  },
  showPage(page) {
    this.currentPage = page;
    // Close the sidebar after selecting a page, unless it's locked
    if (!this.isSidebarLocked) {
      this.isSidebarOpen = false;
    }
  },
  },
};
</script>

<style scoped>

.routerLink{
  text-decoration: none;
}

/* Adjusting the submenu's position */
.dropdown-submenu {
  position: relative;
}



.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;  /* Positioning to the right */
  margin-top: -6px;
  margin-left: 0;
  border-radius: 0.25rem;  /* Optional: Keeps the Bootstrap look */
  /* background-color: transparent; */
  background-color: rgba(2, 2, 2, 0.637);
}

.dropdown-menu{
  /* background-color: transparent; */
  background-color: rgba(2, 2, 2, 0.637);
  border: 1px solid rgb(0, 247, 255);
}



.dropdown-submenu:hover>.dropdown-menu {
  display: block;  /* Ensures the submenu shows on hover */
  color: rgb(255, 0, 0);
}

/* Ensuring the parent dropdown closes when hovering on the submenu */
.dropdown-submenu:hover {
  cursor: pointer;
  color: black;
}

#myapp {
width: 100% !important; /* Full viewport width */
display: flex;
flex-direction: column;
}


.sideBar ul {
  list-style-type: none;
  padding-left: 20px;
  color: white;
  text-align: left !important;
}

.sideBar ul ul {
  padding-left: 20px;
}


.appName{
  display: none;
}

.appNameA{
  display: none;
}

.appNameB{
  margin-left: 20%;
}

.appClass {
  display: flex;
  flex: 1;
}

.sidebar {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0; /* Start from the left edge */
  width: 0px; /* Compact width */
  height: 100dvh; /* Use viewport height for full height */
  background-color: black;
  transition: width 0.3s ease;
  overflow: hidden;
  white-space: nowrap;
}

.sidebar-open {
  width: 80%; /* Expanded width */
  overflow: hidden; /* Prevent scrolling if the content overflows */

}

.lock-btn {
margin: 0;
padding: 0;
border: none;
background: none;
color: white;
font-size: 1.5rem;
cursor: pointer;
}

.lock-btn i {
transition: color 0.3s ease;
}

.lock-btn i.bi-lock {
color: #ffcc00; /* Locked color */
}

.lock-btn i.bi-lock-open {
color: #00ff00; /* Unlocked color */
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.sidebar-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1.1rem;
}

.sidebar-content li {
  margin: 10px 0;
}

.sidebar-content a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  gap: 10px;
  display: flex;
  align-items: center;
  padding: 0px; /* Add padding for better hover effect */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
}

.sidebar-content a:hover {
  background-color: #555555; /* Background color on hover */
  color: #f0f0f0; /* Text color on hover */
  padding: 10px;
  border-radius: 5px;
}

.sidebar-content a span {
  margin-left: 10px;
}

.sidebar-open .sidebar-content a {
  font-size: 1.1rem;
}

.main-content {
  flex: 1;
  margin-left: 0;
  /* margin-left: 60px; */
  transition: margin-left 0.3s ease;
}

.sub-content{
  margin-top: 60px !important;
  margin-left: 60px !important;
}


.shift-right {
  margin-left: 260px; /* Adjust content position */
  transition: margin-left 0.3s ease; /* Smooth transition for margin-left */
}

.nav-item{
  padding-left: 5px;
  padding-right: 5px;
}

.navbar {
  position: fixed;
  z-index: 3;
  width: 100%;
  /* height: 56px; */
  margin-bottom: 1rem;
  padding-left: 15px;
  background-color: rgba(0, 0, 0, 0.452);
  gap: 10px;
}

.sidebar-open + .main-content .loginHeader {
margin-right: 265px;
}

.loginHeader {
  transition: margin-right 0.3s ease; /* Smooth transition for margin-right */
margin-right: 5px; /* Remove margin if it's pushing the header out of view */
}

.mobile-only {
display: none; /* Hide by default (desktop) */
}

.toglebtn{
  position: absolute;
  right: 0%;
 margin-right: 5%; 
}




.close-btn {
  margin: 10px;
}

.nav-link{
  color: white;
}

.dropdown-item{
  color: rgb(255, 255, 255);
}

.dropdown-item:hover{
  color: rgb(0, 0, 0);
}

@media (max-width: 769px) {

  #myapp {
width: 100% !important; /* Full viewport width */
display: flex;
flex-direction: column;
}

  .appName{
  display: block;
}

  .appNameB{
      display: none;
}

.mobile-only {
  display: block; /*Login Header Show only in mobile view */
}

.desktop-only{
  display: none;
}

.shift-right {
  display: none;
}


}
</style>
