<template>
    <!-- Conditionally render snowflakes if current date is between Dec 20 and Dec 31 -->
    <div v-if="isSnowfallSeason" class="snowflakes">
      <div
        v-for="i in snowflakesCount"
        :key="i"
        class="snowflake"
        :style="generateStyle()"
      >
        <i class="bi bi-snow"></i> <!-- Bootstrap snowflake icon -->
      </div>
    </div>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
  
  <!-- <div id="app">
    <router-view/>
  </div> -->

  <div>

  </div>
</template>

<script>
export default {

data() {
  return {
    snowflakesCount: 100, // Number of snowflakes to generate
  }
},
computed: {
    isSnowfallSeason() {
      const today = new Date();
      const start = new Date(today.getFullYear(), 11, 20); // December 20 of the current year
      const end = new Date(today.getFullYear(), 11, 31); // December 31 of the current year
      return today >= start && today <= end;
    },
  },
  methods: {
    generateStyle() {
      const randomLeft = Math.random() * 100; // Random horizontal position
      const randomDelay = Math.random() * 5; // Random delay before falling
      const randomSpeed = 3 + Math.random() * 6; // Random speed
      const randomSize = 6 + Math.random() * 12; // Random size range (10 to 30px)

      return {
        left: `${randomLeft}%`,
        animationDelay: `${randomDelay}s`,
        animationDuration: `${randomSpeed}s`,
        fontSize: `${randomSize}px`, // Use font-size for the icon size
      };
    },
  },
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}




.snowflakes {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999; /* Make sure snowflakes appear above other content */
}

.snowflake {
  position: absolute;
  top: -10px; /* Start from above the viewport */
  color: #fff;
  animation: fall linear infinite;
}

@keyframes fall {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100vh); /* Snowflakes fall to the bottom of the screen */
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .snowflake {
    font-size: 0.8rem; /* Smaller snowflakes for mobile */
  }
}

@media (max-width: 480px) {
  .snowflake {
    font-size: 0.6rem; /* Even smaller snowflakes for very small screens */
  }
}

</style>
