<template>
    <div>
      <div class="rain-container">
        <div v-for="n in 200" :key="n" class="raindrop" :style="generateStyle()"></div>
      </div>
      <div class="graphic-design-page">
        <!-- Main Content -->
        <main class="content">
          <section class="hero">
            <div class="hero-content">
              <h1 class="title">Rainbow Design</h1>
              <p class="subtitle">
                Creativity at its best. Explore our professional graphic design services tailored for your needs.
              </p>
            </div>
          </section>
  
          <!-- Services Section -->
          <section class="services py-5">
            <div class="container">
              <h2 class="text-center mb-4 btn btn-primary" style="color: white; text-transform:uppercase;">Our Services</h2>
              <div class="row g-4">
                <div class="col-md-3">
                  <div class="card text-white" style="background: linear-gradient(45deg, #AB47BC, #FF7043);">
                    <div class="card-body text-center">
                      <h3 class="card-title">Marketing Materials</h3>
                      <p class="card-text">High-quality brochures, flyers, Banners, Posters FaceBook Post, Cards(celebrating, bussiness or wedding) and digital assets.</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card text-white" style="background: linear-gradient(45deg, #FF6F61, #FFD54F);">
                    <div class="card-body text-center">
                      <h3 class="card-title">Logo Design</h3>
                      <p class="card-text">Unique and memorable logos that define your brand. (Cartoon, Vector, Mascot, Word Mark,Pictorial Mark (Icon or Symbol), 3D Logos And Minimalist Logos)</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card text-white" style="background: linear-gradient(45deg, #42A5F5, #66BB6A);">
                    <div class="card-body text-center">
                      <h3 class="card-title">Photo Editing</h3>
                      <p class="card-text">Enhance and transform your images with expert photo editing services. From color correction to background removal, we make your photos stand out.</p>
                    </div>
                  </div>
                </div>
                <!-- Card 5: Animation Design -->
                <div class="col-md-3">
                    <div class="card text-white" style="background: linear-gradient(45deg, #FFC107, #FF5722); animation: bounce 1.5s infinite;">
                        <div class="card-body text-center">
                            <h3 class="card-title">Video & Animation Design</h3>
                            <p class="card-text">Engaging videos to tell your story with impact And Custom animations to make your ideas come alive. (Advertisments or Promtions) </p>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
  
          <!-- Social Media Section -->
          <section class="social-media bg-dark text-white py-5">
            <div class="container">
              <h2 class="text-center mb-4">Follow Us</h2>
              <p class="text-center mb-4">Stay connected with us on social media for the latest updates and offers.</p>
              <div class="text-center">
                <a 
                  href="https://www.facebook.com/unicDNRdesign" 
                  target="_blank" 
                  class="d-inline-flex align-items-center text-decoration-none text-white p-3 mb-4 rounded-3" 
                  style="background: linear-gradient(45deg, #4267B2, #3b5998); box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/1/1b/Facebook_icon.svg" alt="Facebook" width="40" class="me-2" />
                  Rainbow Design
                </a>
              </div>
              <div class="text-center">
                <img
                  src="@/assets/Services/GraphicDesign/RDbackground.jpg"
                  alt="Rainbow Design Logo"
                  class="logo mb-4 animated-border"
                  style="max-width: 500px; border-radius: 10px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);"
                />
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ColorRain",
    methods: {
      generateStyle() {
        const randomLeft = Math.random() * 100; // Random horizontal position
        const randomDelay = Math.random() * 5; // Random delay
        const randomSpeed = 3 + Math.random() * 5; // Random speed
        const randomSize = 5 + Math.random() * 10; // Random size
        const randomColor = `hsl(${Math.random() * 360}, 100%, 50%)`; // Random color
  
        return {
          left: `${randomLeft}%`,
          animationDelay: `${randomDelay}s`,
          animationDuration: `${randomSpeed}s`,
          width: `${randomSize}px`,
          height: `${randomSize * 2}px`,
          backgroundColor: randomColor,
        };
      },
    },
  };
  </script>
  
  <style scoped>
  /* Main container styling */
  .rain-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1; /* Push the animation behind the content */
    background-color: #000; /* Optional background color */
  }
  
  /* Individual raindrop */
  .raindrop {
    position: absolute;
    top: -10%; /* Start just outside the view */
    border-radius: 50%;
    animation: fall linear infinite;
  }
  
  /* Fall animation */
  @keyframes fall {
    0% {
      transform: translateY(-10%);
    }
    100% {
      transform: translateY(110vh); /* Drop outside the viewport */
    }
  }
  
  /* Content styling */
  .content {
    color: #fff;
    text-align: center;
    padding-top: 10%;
  }
  
  .logo {
    width: 450px;
    height: 200px;
  }
    
   /* logo Border color animation */
   .animated-border {
    border: 5px solid transparent; /* Initial border */
    animation: borderColorChange 2s infinite alternate; /* Animation for border color */
  }

  @keyframes borderColorChange {
    0% {
      border-color: #FFC107; /* Initial border color */
    }
    50% {
      border-color: #FF5722; /* Midway color */
    }
    100% {
      border-color: #42A5F5; /* Final border color */
    }
  }
  /* logo border color animation end */
  
  .graphic-design-page {
    font-family: Arial, sans-serif;
    color: #333;
    background: url("@/assets/Services/GraphicDesign/logo for page.png") no-repeat center center fixed;
    background-size: cover;
    background-size: 40%;
  }
  
  .hero {
    background: rgba(0, 0, 0, 0.5); /* Add a slight overlay for better text readability */
    color: #fff;
    text-align: center;
    padding: 2rem 1rem;
  }
  
  .hero .title {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }
  
  .hero .subtitle {
    font-size: 1.2rem;
    margin: 0;
  }
  
  .services {
    padding: 2rem 1rem;
  }
  
  .services h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 2rem;
    color: #007bff;
  }
  
  .services .card {
    text-align: center;
  }
  
  .social-media {
    padding: 2rem 1rem;
    text-align: center;
  }
  
  .social-media h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #007bff;
  }


@media (max-width: 767px) {
  .logo {
    width: 250px;
    height: 100px;
  }
}
  
  </style>
  