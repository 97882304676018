<template>
  <div class="classMain">
    <section class="services-section py-5">
      <div class="container">
        <!-- Section Title -->
        <div class="section-title text-center mb-5">
          <h2 class="display-4">Our Services</h2>
          <p class="lead">We offer a wide range of professional services to meet your needs.</p>
        </div>
        <!-- Services Grid -->
        <div class="row">
          <div
            v-for="(service, index) in services"
            :key="index"
            class="col-lg-3 col-md-6 mb-4 d-flex align-items-stretch"
          >
            <div class="service-card p-4 text-center position-relative">
              <div class="icon-wrapper mb-3">
                <i :class="service.icon"></i>
              </div>
              <h5 class="service-title">{{ service.title }}</h5>
              <p class="service-description">{{ service.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ServicesSection",
  data() {
    return {
      services: [
        {
          icon: "bi bi-palette",
          title: "Graphic Design",
          description: "Create stunning visuals, logos, and branding materials that captivate and leave a lasting impression.",
        },
        {
          icon: "bi bi-code-slash",
          title: "Web Development",
          description: "Build responsive, high-performance websites tailored to your business needs.",
        },
        {
          icon: "bi bi-phone",
          title: "Mobile App Development",
          description: "Create intuitive and powerful mobile applications for iOS and Android platforms.",
        },
        {
          icon: "bi bi-windows",
          title: "Windows Application",
          description: "Develop custom Windows applications that meet your business requirements and enhance productivity.",
        },
      ],
    };
  },
};
</script>

<style scoped>

.classMain{
  width:100%;
  height: 100%;
}
.services-section {
  background-color: #f8f9fa;
  overflow: hidden;
}

.section-title h2 {
  font-weight: 700;
  margin-bottom: 10px;
}

.section-title p {
  color: #6c757d;
  margin-bottom: 0;
}

.service-card {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  opacity: 0;
  transform: translateY(50px);
  height: 100%; /* Ensures all cards have the same height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-card .icon-wrapper {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: rotateCircle 10s infinite linear;
}

.service-card .icon-wrapper i {
  font-size: 2rem;
  color: #ffffff;
}

.service-card .service-title {
  font-weight: 600;
  margin-bottom: 15px;
}

.service-card .service-description {
  color: #6c757d;
}

@keyframes rotateCircle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Slide-in animation */
.service-card {
  animation: slideIn 0.8s forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Make icons and text size responsive */
.service-card .icon-wrapper {
  width: 60px;
  height: 60px;
}

.service-card .icon-wrapper i {
  font-size: 1.5rem;
}

.service-card .service-title {
  font-size: 1.25rem;
}

@media (max-width: 768px) {

  .service-card {
    animation: slideIn 0.8s forwards;
  }
  
  .section-title h2 {
    font-size: 2rem;
  }

  .section-title p {
    font-size: 1rem;
  }

  .service-card .icon-wrapper {
    width: 50px;
    height: 50px;
  }

  .service-card .icon-wrapper i {
    font-size: 1.25rem;
  }

  .service-card .service-title {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .section-title h2 {
    font-size: 1.75rem;
  }

  .section-title p {
    font-size: 0.875rem;
  }

  .service-card .icon-wrapper {
    width: 40px;
    height: 40px;
  }

  .service-card .icon-wrapper i {
    font-size: 1rem;
  }

  .service-card .service-title {
    font-size: 0.875rem;
  }
}
</style>
